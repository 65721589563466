/**
 * Because the slider's ::after is not configurable via inline Tailwind styles,
 * We're writing a custom CSS class to override the slider's ::after styles.
 * 
 * This should be attached to the <Slider className="audio-player" />
 */
.audio-player .ant-slider-handle::after {
  /** The dot color **/
  background-color: var(--semantic-red) !important;

  /** The border around the dot (it's actually a box-shadow) **/
  box-shadow: 0 0 0 2px transparent !important;
}
