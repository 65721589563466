/** Only target when editor is editable */
.tiptap[contenteditable="true"] {
  /** Adds a highlight border when an image is focused in the editor cursor. */
  img {
    outline: 2px solid transparent;

    &.ProseMirror-selectednode {
      outline: 2px solid var(--tpl-blue);
      border-radius: 2px;
    }
    transition-property: border-radius, border-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
}

/* .editor-container {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px;
  background-color: #fff;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.custom-tiptap-editor {
  min-height: 120px;
  padding: 8px 11px;
  font-size: 14px;
  color: #000;
  line-height: 1.5;
  background-color: #fff;
  border: none;
  outline: none;
  resize: none;
  box-shadow: none;
  overflow-y: auto;
}

.editor-container:focus-within {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.editor-container[disabled] {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  color: #bfbfbf;
  cursor: not-allowed;
} */
